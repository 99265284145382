import logo from "../src/assets/images/brands/logo.jpg";
import sLogo from "../src/assets/images/brands/smallLogo.jpg";
import darkLogo from "../src/assets/images/brands/darkLogo.png";


export const clientName = "ARB VISTA";
export const developedBy = "ARB VISTA";

export const imagesUrl = process.env.REACT_APP_API_IMAGES_URL || "http://localhost:3001/static/";
export const cpUrl = process.env.REACT_APP_CP_URL || "http://localhost:4002/";
export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:3001/";
export const companyName =  "ARB VISTA";
export const sidebarLogo = logo;
export const smallLogo = sLogo;
export const mainLogo = logo;
export const lightLogo = darkLogo;